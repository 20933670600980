import { NextPage } from 'next';
import React, { createContext, useState } from 'react';

type MenuContextType = {
  isMenuOpen: boolean;
  toggleMenu: (value: React.SetStateAction<boolean>) => void;
};
export const MenuContext = createContext<MenuContextType>({
  isMenuOpen: false,
  toggleMenu: () => ({}),
});
type NavStateT = {
  children: React.ReactNode;
};
const NavState: NextPage<NavStateT> = ({ children }) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  /*
  function ToggleMenuMode() {
    toggleMenu(!isMenuOpen);
  }
*/
  return (
    <MenuContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

export default NavState;
