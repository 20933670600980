//import Image from 'next/image';
//import Link from 'next/link';
import styled, { css } from 'styled-components';
import IconsHamburgerMenuComponent from 'components/atoms/buttons/Hamburger';
import FacebookIcon from 'components/atoms/icons/Facebook';
import InstagramIcon from 'components/atoms/icons/Instagram';
import { useContext } from 'react';

//import useTranslation from 'next-translate/useTranslation';
import LanguageSwitcher from 'components/atoms/icons/LanguageSwitcher';
import { NextPage } from 'next';
import { MenuContext } from 'context/NavState';

export const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  height: 54px;
  width: 115px;
  cursor: pointer;
`;

type NavItemsT = {
  isOpen: boolean;
};
export const NavItems = styled.nav<NavItemsT>`
  display: flex;
  flex: 1;
  //align-items: center;
  justify-content: center;
  //text-align: center;
  @media screen and (max-width: 1024px) {
    //display: none;
    flex-flow: column nowrap;

    color: #292838;
    justify-content: start;
    background-color: white;
    padding: 30px;
    position: absolute;
    z-index: 1;
    padding-top: 5rem;
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    ${(props) =>
      !props.isOpen &&
      css`
        visibility: hidden;
        transform: translateX(0);
        background-color: black;
      `}
  }
`;

export const NavItem = styled.a`
  color: ${(props) => props.theme.colors.darkSlateGray};
  font-family: ${(props) => props.theme.fonts.desktopBodyMedium.family};
  font-size: ${(props) => props.theme.fonts.desktopBodyMedium.size};
  font-weight: ${(props) => props.theme.fonts.desktopBodyMedium.weight};
  line-height: ${(props) => props.theme.fonts.desktopBodyMedium.lineHeight};
  text-decoration: none;
  /* Desktop/Body/Large */
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Jost;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 170%;
  }
  /* identical to box height, or 34px */
  &:not(:last-of-type) {
    margin-right: 56px;
  }
`;

const SidebarUnderline = styled.hr`
  visibility: hidden;
  @media screen and (max-width: 1024px) {
    visibility: inherit;
  }
`;

export const MobileIcon = styled.nav`
  display: flex;
  align-items: center;
  //position: fixed;
  //margin-left: 20px;
  z-index: 2;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
export const SocialIcons = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const NavBtnLink = styled.nav``;

type NavbarType = {
  className?: string;
};

const NavBar: NextPage<NavbarType> = ({ className }): JSX.Element => {
  //const { t, lang } = useTranslation('common');
  //console.log(t('greeting'), lang);
  const { isMenuOpen } = useContext(MenuContext);
  //console.log(isMenuOpen);
  return (
    <NavBarContainer className={className}>
      <NavItem href="/">
        <LogoWrapper>
          <img
            //unoptimized={true}
            src="/assets/logo.svg"
            alt="logo"
            width="115px"
            height="54px"
          />
        </LogoWrapper>
      </NavItem>
      <NavItems isOpen={isMenuOpen}>
        <NavItem href="/#hero">О нас</NavItem>
        <NavItem href="/#directions">Направления</NavItem>
        <NavItem href="/#price">Цены</NavItem>
        <NavItem href="/#schedule">Расписание</NavItem>
        <NavItem href="/#footer">Контакты</NavItem>
        <SidebarUnderline></SidebarUnderline>
      </NavItems>
      <SocialIcons>
        <LanguageSwitcher></LanguageSwitcher>
        <a
          href="https://instagram.com/shamry.art.space?igshid=12i81td90h6zn"
          aria-label="Instagram"
        >
          <InstagramIcon></InstagramIcon>
        </a>
        <a
          href="https://www.facebook.com/ShamryArtSpace/"
          aria-label="Facebook"
        >
          <FacebookIcon></FacebookIcon>
        </a>
        <MobileIcon>
          <IconsHamburgerMenuComponent />
        </MobileIcon>
      </SocialIcons>
    </NavBarContainer>
  );
};

export default NavBar;
