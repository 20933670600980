import { NextPage } from 'next';
//import Image from 'next/image';
import styled from 'styled-components';

export type IconDataT = {
  className?: string;
  src: string;
  height?: string;
  width?: string;
};
const IconComponentWrapper = styled.div``;
const IconComponent: NextPage<IconDataT> = ({
  className,
  src,
  height = 28,
  width = 28,
}): JSX.Element => {
  return (
    <IconComponentWrapper className={className}>
      <img src={src} height={height} width={width} alt="icon"></img>
    </IconComponentWrapper>
  );
};

export default IconComponent;
