import { NextPage } from 'next';
import styled from 'styled-components';
//import Image from 'next/image';

export type LocationDataT = {
  className?: string;
  id: number;
  lat?: number;
  mapImage: string;
  mapUrl?: string;
  long?: number;
  city?: string;
  adress: string;
  adressIconUrl?: string;
  description: string;
};
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 20px auto;
  grid-gap: 5px;
  margin-bottom: 10px;
`;

const Img = styled.img`
  //max-width: 100%;
  object-fit: cover;

  //max-height: 100%;
  height: 100%;
  width: 100%;
`;
const MapImageWrapper = styled.a`
  position: relative;
  position: relative;
  //width: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /*min-width: 345px;
  min-height: 107px;
  max-height: 126px;
  max-width: 403px;*/
`;

const ImageWrapper = styled.div`
  grid-area: 1 / 1 / 2 / 2;

  /*
grid-area is shorthand for 
grid-row-start, grid-column-start, grid-row-end and grid-column-end.
*/
`;
const AdressWrapper = styled.div`
  grid-area: 1 / 2 / 2 / 2;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #292838;
`;
const DescriptionWrapper = styled.div`
  grid-area: 2 / 2 / 2 / 2;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #292838;
`;
const LocationComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
/*
    lat: 50.49904169803162,
    long: 30.49792072862715,
    */
const LocationComponent: NextPage<LocationDataT> = ({
  className,
  adress,
  description,
  mapImage,
  lat,
  long,
}) => {
  return (
    <LocationComponentWrapper className={className}>
      <HeaderWrapper>
        <ImageWrapper>
          <img
            height="20"
            width="20"
            src="/assets/icons/Location.svg"
            alt="icon"
          ></img>
        </ImageWrapper>
        <AdressWrapper>{adress}</AdressWrapper>
        <DescriptionWrapper>{description}</DescriptionWrapper>
      </HeaderWrapper>
      <MapImageWrapper href={`https://maps.google.com/?q=${lat},${long}`}>
        <Img src={mapImage} alt="map"></Img>
      </MapImageWrapper>
    </LocationComponentWrapper>
  );
};
export default LocationComponent;

/*import { GoogleMap, LoadScript } from '@react-google-maps/api';
    <LoadScript googleMapsApiKey="AIzaSyBm-VLz9EKZqfgRdNx4GhsR63jIrCHVd7A">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <></>
      </GoogleMap>
      //AIzaSyBm-VLz9EKZqfgRdNx4GhsR63jIrCHVd7A

    </LoadScript>*/
