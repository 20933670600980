import styled from 'styled-components';
import IconArrowDown from 'components/atoms/icons/ArrowDown';

export const LanguageSwitcherComponent = styled.div`
  display: flex;
  width: 100px;
  align-items: center;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const DefaultElement = styled.div`
  /* Desktop/Body/Button */

  font-family: Jost;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.03em;
  text-transform: uppercase;

  /* Action/pressed */

  color: ${(props) => props.theme.colors.purple};
`;

const LanguageSwitcher = (): JSX.Element => {
  return (
    <LanguageSwitcherComponent>
      <DefaultElement>RU</DefaultElement>
      <IconArrowDown />
    </LanguageSwitcherComponent>
  );
};

export default LanguageSwitcher;
/*
            <Link href={router.asPath} locale={locale}>
              <IconLocaleA>{locale}</IconLocaleA>
            </Link>


import styled from 'styled-components';
import { useRouter } from 'next/router';
import Select, { Option } from 'rc-select';
//import Link from 'next/link';

export const IconsLightOutlineInstagram = styled.div`
  width: 32px;
  height: 32px;
  margin-bottom: 25px;
`;

export const IconLocaleA = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
export const IconLocaleUl = styled.ul`
  list-style: none;
`;
export const IconLocaleLi = styled.ul`
  list-style: none;
`;
export const IconLocaleSelect = styled(Select)`
  list-style: none;
`;
export const IconLocalOption = styled(Option)`
  list-style: none;
`;

const LanguageSwitcher = (): JSX.Element => {
  const router = useRouter();
  const { locale } = router;

  const onSelectHandler = async (selected: string) => {
    const locale = selected;
    await router.push(router.asPath, router.asPath, {
      locale,
      scroll: false,
    });
  };
  return (
    <IconsLightOutlineInstagram>
      <IconLocaleSelect
        showSearch={false}
        defaultOpen={false}
        showArrow={true}
        value={locale}
        onChange={onSelectHandler}
      >
        {router.locales.map((l) => (
          <IconLocalOption value={l} key={l}>
            {l.toLocaleUpperCase()}
          </IconLocalOption>
        ))}
      </IconLocaleSelect>
    </IconsLightOutlineInstagram>
  );
};
            */
