import React, { useContext } from 'react';

import styled from 'styled-components';
import { MenuContext } from 'context/NavState';

const MenuButton = styled.button`
  display: block;
  transform-origin: 16px 11px;
  float: left;
  //margin-right: 29px;
  outline: 0;
  border: 0;
  //padding: 12px;
  background: none;

  span {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  :focus {
    border: medium none #917c93;
    box-shadow: #917c93 0 0 2px 2px;
    outline: 0;
  }

  :hover {
    span:nth-of-type(1) {
      width: 33px;
    }

    span:nth-of-type(2) {
      width: 40px;
    }

    span:nth-of-type(3) {
      width: 30px;
    }
  }

  &.active {
    span:nth-of-type(1) {
      transform: rotate(45deg) translate(10px, 10px);
      width: 40px;
    }

    span:nth-of-type(2) {
      opacity: 0;
      pointer-events: none;
    }

    span:nth-of-type(3) {
      transform: rotate(-45deg) translate(7px, -7px);
      width: 40px;
    }
  }
`;

export const IconsHamburgerMenu = styled.div`
  display: flex;
  align-items: center;
`;

const IconsHamburgerMenuComponent = (): JSX.Element => {
  const { isMenuOpen, toggleMenu } = useContext(MenuContext);
  const clickHandler = () => {
    toggleMenu(isMenuOpen ? false : true);
  };
  return (
    <MenuButton
      className={isMenuOpen ? 'active' : ''}
      aria-label="Открыть главное меню"
      onClick={clickHandler}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" />
        <path
          d="M24.9411 23.363C25.5124 23.363 25.975 23.9531 25.975 24.6815C25.975 25.41 25.5124 26.0001 24.9411 26.0001H7.03529C6.464 26.0001 6 25.41 6 24.6815C6 23.9531 6.464 23.363 7.03529 23.363H24.9411Z"
          fill="#917C93"
        />
        <path
          d="M24.8382 14.6805C25.2369 14.6259 25.627 14.8663 25.8401 15.2979C26.0533 15.7331 26.0533 16.2831 25.8401 16.7184C25.627 17.15 25.2369 17.3904 24.8382 17.3358H6.93234C6.40165 17.2593 6 16.6892 6 16.0081C6 15.327 6.40165 14.7552 6.93234 14.6805H24.8382Z"
          fill="#917C93"
        />
        <path
          d="M16.8472 6C17.3779 6.07467 17.7796 6.64651 17.7796 7.32762C17.7796 8.00691 17.3779 8.57875 16.8472 8.65341H6.93379C6.40165 8.57875 6 8.00691 6 7.32762C6 6.64651 6.40165 6.07467 6.93379 6H16.8472Z"
          fill="#917C93"
        />
      </svg>
    </MenuButton>
  );
};

export default IconsHamburgerMenuComponent;
/*

*/
