import type { AppProps /*, AppContext */ } from 'next/app';
import styled, { ThemeProvider } from 'styled-components';
import globalTheme from 'theme';
import NavStateContext from 'context/NavState';
import { LocationData } from 'pages/api/locations';
import FooterComponent from 'components/organisms/Footer';
import NavBar from 'components/organisms/Navbar';
import Head from 'next/head';
import { Init } from 'utils/debug';
import './globals.css';
//import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
//import { TrackingHeadScript } from '@phntms/react-gtm';

const FooterBackground = styled.div`
  //display: grid;
  grid-area: 3 / 1 / 4 / 4;
  background-color: #f1edef;
`;

const FooterWrapper = styled(FooterComponent)`
  justify-self: stretch;

  grid-area: 3 / 2 / 4 / 3;

  /*
grid-area is shorthand for 
grid-row-start, grid-column-start, grid-row-end and grid-column-end.
*/
`;

const NavBarStyled = styled(NavBar)`
  justify-self: stretch;
  margin-top: 20px;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
`;

const HeroBackground = styled.div`
  //display: grid;
  background-color: #f1edef;

  grid-area: 1 / 1 / 2 / 4;
`;

const Page = styled.div`
  display: grid;

  //align-items: center;
  grid-template-rows: auto auto auto;
  grid-template-columns: 2rem minmax(auto, 1440px) 2rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 20px auto 20px;
  }
  //minmax(auto, 1440px)
`;
const Content = styled.div`
  grid-column: 1 / 4;
  grid-row: 2 /3;
`;
/*
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';*/
//import '@fullcalendar/timegrid/main.css';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  Init();

  return (
    <ThemeProvider theme={globalTheme}>
      <NavStateContext>
        <Head>
          <title>Shamry Art Space: Балет, Растяжка, Партерный Тренаж</title>
          <link rel="icon" href="/favicon.ico" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            name="description"
            content="Занятия для взрослых в Киеве на Оболони, Печерске, Борщаговке, видимый результат уже за 3 месяца. Записаться сейчас."
          ></meta>
          <meta name="google" content="notranslate" />
          <meta
            property="og:title"
            content="Shamry Art Space: Балет, Растяжка, Партерный Тренаж"
          />
          <meta
            property="og:description"
            content="Занятия для взрослых в Киеве на Оболони, Печерске, Борщаговке, видимый результат уже за 3 месяца. Записаться сейчас."
          />
          <meta property="og:url" content="https://shamryartspace.com/" />
          <meta property="og:type" content="website" />
        </Head>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Page>
            <HeroBackground />
            <NavBarStyled />
            <Content>
              <Component {...pageProps} />
            </Content>
            <FooterBackground></FooterBackground>
            <FooterWrapper locationData={LocationData}></FooterWrapper>
          </Page>
        </div>
      </NavStateContext>
    </ThemeProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
