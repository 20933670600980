const globalTheme = {
  fonts: {
    heading: {
      family: 'Cormorant',
      style: 'normal',
      weight: 600,
      size: '55px',
      lineHeight: '120%',
    },
    desktopBodyButton: {
      family: `'Jost', sans-serif;`,
      size: '16px',
      weight: 400,
      lineHeight: '150%',
    },
    desktopBodyMedium: {
      family: `'Jost', sans-serif;`,
      size: '16px',
      weight: 300,
      lineHeight: '150%',
    },
  },
  colors: {
    primary: '#0070f3',
    background: '#F1EDEF',
    lightPurple: 'C3B3C5',
    purple: '#917C93',
    white: '#FFFFFF',
    darkSlateGray: '#292838',
  },
};

export default globalTheme;
