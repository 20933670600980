import React from 'react';

import styled from 'styled-components';

export const IconsLightOutlineInstagram = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.purple};
  border-radius: 50%;
  justify-content: center;
`;

const IconsLightOutlineFacebook = (): JSX.Element => {
  return (
    <IconsLightOutlineInstagram>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0341 5.18447C16.3739 5.18447 16.6493 4.9089 16.6493 4.56924V0.615234C16.6493 0.275414 16.3739 0 16.0341 0H11.4209C9.26488 0 7.51083 1.75406 7.51083 3.9101V7.90784H4.96289C4.62307 7.90784 4.34766 8.18326 4.34766 8.52308V12.4769C4.34766 12.8167 4.62307 13.0922 4.96289 13.0922H7.51083V20.3848C7.51083 20.7246 7.78624 21 8.12606 21H12.0801C12.4199 21 12.6953 20.7246 12.6953 20.3848V13.0922H15.2432C15.5366 13.0922 15.7891 12.8852 15.8466 12.5976L16.6373 8.64372C16.6735 8.463 16.6267 8.27554 16.5098 8.13295C16.393 7.99052 16.2183 7.90784 16.0341 7.90784H12.6953V5.18447H16.0341ZM12.0801 9.13831H15.2836L14.7389 11.8617H12.0801C11.7402 11.8617 11.4648 12.1371 11.4648 12.4769V19.7695H8.74129V12.4769C8.74129 12.1371 8.46588 11.8617 8.12606 11.8617H5.57812V9.13831H8.12606C8.46588 9.13831 8.74129 8.8629 8.74129 8.52308V3.9101C8.74129 2.43258 9.94341 1.23047 11.4209 1.23047H15.4188V3.954H12.0801C11.7402 3.954 11.4648 4.22942 11.4648 4.56924V8.52308C11.4648 8.8629 11.7402 9.13831 12.0801 9.13831Z"
          fill="white"
        />
      </svg>
    </IconsLightOutlineInstagram>
  );
};

export default IconsLightOutlineFacebook;
