import React from 'react';

import styled from 'styled-components';

export const ArrowDown = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

const Icon = (): JSX.Element => {
  return (
    <ArrowDown>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.18077 5.80942C3.39992 5.58588 3.74285 5.56556 3.98451 5.74846L4.05374 5.80942L8.99997 10.8551L13.9462 5.80942C14.1653 5.58588 14.5083 5.56556 14.7499 5.74846L14.8192 5.80942C15.0383 6.03297 15.0582 6.38278 14.8789 6.62928L14.8192 6.6999L9.43645 12.1906C9.2173 12.4141 8.87437 12.4344 8.63272 12.2515L8.56348 12.1906L3.18077 6.6999C2.9397 6.454 2.9397 6.05532 3.18077 5.80942Z"
          fill="#917C93"
        />
      </svg>
    </ArrowDown>
  );
};

export default Icon;
