import { NextApiRequest, NextApiResponse } from 'next';

type LocationDataT = {
  id: number;
  lat: number;
  mapImage: string;
  mapUrl: string;
  long: number;
  city: string;
  adress: string;
  adressIconUrl: string;
  description: string;
};

export const LocationData: LocationDataT[] = [
  {
    id: 1,
    city: 'Киев',
    mapImage: '/assets/locations/klovskaya8.avif',
    mapUrl: 'google.com',
    lat: 50.44106180458386,
    long: 30.537937083114098,
    adress: 'Кловский спуск, 8',
    adressIconUrl: 'assets/icons/Location.svg',
    description: '10 мин. от метро Кловская',
  },
  {
    id: 2,
    city: 'Киев',
    mapImage: '/assets/locations/obolonskyprospekt1.avif',
    mapUrl: 'google.com',
    lat: 50.49904169803162,
    long: 30.49792072862715,
    adress: 'Оболонский проспект, 1',
    adressIconUrl: 'assets/icons/Location.svg',
    description: '5 мин. от метро Оболонь',
  },
  {
    id: 3,
    city: 'Киев',
    mapImage: '/assets/locations/koltsova14g.avif',
    mapUrl: 'google.com',
    lat: 50.424040660738726,
    long: 30.38404624087217,
    adress: 'Бульвар Кольцова, 14ж',
    adressIconUrl: 'assets/icons/Location.svg',
    description: 'Борщаговка, 3 мин. от скоростного трамвая',
  },
  {
    id: 3,
    city: 'Киев',
    mapImage: '/assets/locations/koltsova14g.avif',
    mapUrl: 'google.com',
    lat: 50.4440516,
    long: 30.6041053,
    adress: 'Евгена Cверстюка, 4а',
    adressIconUrl: 'assets/icons/Location.svg',
    description: 'Днепровский, 10 мин. от метро Левобережная',
  },
];
export default function handler(
  req: NextApiRequest,
  res: NextApiResponse,
): void {
  res.status(200).json(LocationData);
}
