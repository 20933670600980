import { NextPage } from 'next';
import styled from 'styled-components';
import Location, { LocationDataT } from 'components/molecules/Location';
import IconComponent from 'components/atoms/icon';

type FooterDataT = {
  id?: string;
  className?: string;
  locationData: LocationDataT[];
};
const MapSectionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 45px;
`;
const Line = styled.hr`
  margin-top: 75px;
  margin-bottom: 56px;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
const Contacts = styled.div`
  //margin-top: 56px;
`;
const Copyrights = styled.div`
  align-self: flex-end;

  justify-self: end;
  display: flex;
  text-align: end;
  flex-direction: column;
  align-content: flex-end;
`;
const FooterSectionWrapper = styled.div`
  //margin-left: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 48px;
`;
const FooterComponentWrapper = styled.div``;
const SocialIcons = styled.div`
  display: flex;
  margin-top: 32px;
`;
const IconComponentWrapper = styled(IconComponent)`
  margin-right: 8px;
`;
const AllRightsReserved = styled.div`
  margin-top: 32px;
  text-align: left;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  color: #292838;
`;
const NameAndYear = styled.div`
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  color: #292838;
`;
const AreAnyQuestions = styled.div`
  /* Desktop/Heading/H2 */
  font-family: Cormorant;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #292838;
`;
const ContactToMe = styled.div`
  margin-top: 4px;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  color: #292838;
`;
const PhoneNumber = styled.div`
  display: flex;
  margin-top: 24px;
  /* Desktop/Body/Medium */

  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #292838;
`;
const EmailAdress = styled.div`
  display: flex;

  /* Desktop/Body/Medium */
  margin-top: 16px;

  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #292838;
`;
const FooterComponent: NextPage<FooterDataT> = ({
  className,
  locationData,
  id,
}) => {
  return (
    <FooterComponentWrapper className={className} id={id ? id : 'footer'}>
      <MapSectionWrapper>
        {locationData.map((c) => (
          <Location
            key={c.id}
            id={c.id}
            mapImage={c.mapImage}
            adress={c.adress}
            description={c.description}
            lat={c.lat}
            long={c.long}
          ></Location>
        ))}
      </MapSectionWrapper>
      <Line></Line>
      <FooterSectionWrapper>
        <Contacts>
          <AreAnyQuestions>Остались Вопросы?</AreAnyQuestions>
          <ContactToMe>Свяжитесь со мной!</ContactToMe>
          <PhoneNumber>
            <IconComponentWrapper
              height="20"
              width="20"
              src="/assets/icons/Phone.svg"
            />
            <div>+380959417861</div>
          </PhoneNumber>
          <EmailAdress>
            <IconComponentWrapper
              height="20"
              width="20"
              src="/assets/icons/Envelope.svg"
            />
            <div>shamryartspace@gmail.com</div>
          </EmailAdress>
          <SocialIcons>
            <a href="https://instagram.com/shamry.art.space?igshid=12i81td90h6zn">
              <IconComponentWrapper
                height="32"
                width="32"
                src="/assets/icons/Instagram.svg"
              />
            </a>

            <a href="https://www.facebook.com/ShamryArtSpace/">
              <IconComponentWrapper
                height="32"
                width="32"
                src="/assets/icons/Facebook.svg"
              />
            </a>
          </SocialIcons>
        </Contacts>
        <Copyrights>
          <AllRightsReserved>Все права защищены</AllRightsReserved>
          <NameAndYear>Shamry Art Space © 2021</NameAndYear>
        </Copyrights>
      </FooterSectionWrapper>
    </FooterComponentWrapper>
  );
};

export default FooterComponent;
